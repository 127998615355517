$(document).ready(initPage);

function initPage() {
  $('.header-lang .lang-btn').click(function () {
    $(this).parent().toggleClass('is-active');
    $('.lang-dropdown-bg').toggleClass('is-active');
  });

  $('.header-toggler').click(function () {
    $('.header').toggleClass('is-menu-expanded');
    $('body').toggleClass('is-menu-expanded');
    $('.header-lang').removeClass('is-active');
    $('.lang-dropdown-bg').removeClass('is-active');
  });

  $('.header-menu .dropdown > a, .header-menu .dropdown > span').click(
    function (event) {
      var windowWidth = $(window).width();
      if (windowWidth < 1280) {
        event.preventDefault();
        $(this).parent().toggleClass('is-m-expanded');
      }
    }
  );
  $('.lang-dropdown-close, .lang-dropdown-bg').click(function () {
    $('.header-lang').removeClass('is-active');
    $('.lang-dropdown-bg').removeClass('is-active');
  });

  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }

  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });

  /*** Homepage ***/
  const swiperHeroHome = new Swiper('.swiper--hero-home', {
    slidesPerView: '1',
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 40,
    speed: 1000,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    on: {
      slideChange: function () {
        const activeIndex = this.activeIndex;
        $('.hero-links ul li')
          .eq(activeIndex)
          .addClass('is-active')
          .siblings()
          .removeClass('is-active');
      },
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });
  $('.section-hero-home .hero-links ul li').on('click', function () {
    var index = $(this).index();
    swiperHeroHome.slideTo(index, 500);
    $(this).addClass('is-active').siblings().removeClass('is-active');
  });

  $('.more a').on('click', function (event) {
    event.preventDefault();
    var targetId = $(this).attr('href');
    $('html, body').animate(
      {
        scrollTop: $(targetId).offset().top - $('.header').outerHeight(),
      },
      1000
    );
  });

  const swiperBuiltFor = new Swiper('.swiper--built-for', {
    slidesPerView: '1',
    spaceBetween: 40,
    speed: 500,
    on: {
      slideChange: function () {
        const activeIndex = this.activeIndex;
        $('.nav-item')
          .eq(activeIndex)
          .addClass('is-active')
          .siblings()
          .removeClass('is-active');
      },
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });

  $('.built-for .nav-item').on('click', function () {
    var index = $(this).index();
    swiperBuiltFor.slideTo(index, 500);
    $(this).addClass('is-active').siblings().removeClass('is-active');
  });

  const swiperTestimonials = new Swiper('.swiper--home-testimonials', {
    slidesPerView: '1',
    spaceBetween: 40,
    loop: true,
    navigation: {
      nextEl: '.section-testimonials .swiper-next',
      prevEl: '.section-testimonials .swiper-prev',
    },
  });

  const swiperCustomers = new Swiper('.swiper--customers', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    speed: 5000,
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });

  const swiperCustomers2 = new Swiper('.swiper--customers-2', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    speed: 5000,
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });

  /*** Blog ***/
  const swiperBlogFeatured = new Swiper('.swiper--blog-featured', {
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    pagination: {
      el: '.swiper--blog-featured .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  $('.blog-view').click(function () {
    if (!$(this).hasClass('is-active')) {
      $(this).addClass('is-active').siblings().removeClass('is-active');
      const activeView = $(this).attr('data-view');
      $('.post-list').attr('data-view', activeView);
      // if (activeView == 'grid') {
      //   $('.blog-row').addClass('no-sidebar');
      // } else {
      //   $('.blog-row').removeClass('no-sidebar');
      // }

      $('.post-list .card').each(function () {
        if ($(this).hasClass('card--post-wide')) {
          $(this).removeClass('card--post-wide').addClass('card--post');
        } else if ($(this).hasClass('card--post')) {
          $(this).removeClass('card--post').addClass('card--post-wide');
        }
      });
    }
  });

  /*** Post ***/

  $('.img-enlarge a').magnificPopup({ type: 'image' });

  /*** About ***/

  const swiperTimeline = new Swiper('.swiper--timeline ', {
    slidesPerView: 1,
    spaceBetween: 50,
    speed: 500,
    on: {
      slideChange: function () {
        const activeIndex = this.activeIndex;
        $('.timeline-nav-item')
          .eq(activeIndex)
          .addClass('is-active')
          .siblings()
          .removeClass('is-active');
      },
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });

  $('.timeline-nav-item').click(function () {
    const activeIndex = $(this).index();
    swiperTimeline.slideTo(activeIndex, 500);
  });

  const swiperTeam = new Swiper('.swiper--team ', {
    slidesPerView: 1,
    spaceBetween: 40,
    speed: 500,
    navigation: {
      nextEl: '.section-team .swiper-next',
      prevEl: '.section-team .swiper-prev',
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });

  /*** Career ***/
  const swiperCareerTeam = new Swiper('.swiper--career-team ', {
    slidesPerView: 'auto',
    spaceBetween: 58,
    speed: 500,
    centeredSlides: true,
    centeredSlidesBounds: true,
    autoplay: true,
    loop: true,
    navigation: {
      nextEl: '.section-career-team .swiper-next',
      prevEl: '.section-career-team .swiper-prev',
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
      1600: {
        spaceBetween: 58,
      },
    },
  });

  $('select').selectric({
    maxHeight: 400,
    expandToItemText: true,
  });

  function toggleFirstSelectedClass(selectElement) {
    const firstSelected = selectElement.prop('selectedIndex') === 0;
    const selectWrapper = selectElement.closest('.selectric-wrapper');

    if (firstSelected) {
      selectWrapper.addClass('selectric-default');
    } else {
      selectWrapper.removeClass('selectric-default');
    }
  }

  toggleFirstSelectedClass($('select'));

  $('select').on('selectric-change', function (event, element, selectric) {
    toggleFirstSelectedClass($(element));
  });

  /*** Company ***/

  const swiperCompany = new Swiper('.swiper--company-slider ', {
    slidesPerView: 1,
    spaceBetween: 50,
    speed: 500,
    on: {
      slideChange: function () {
        const activeIndex = this.activeIndex;
        $('.company-slider-nav-item')
          .eq(activeIndex)
          .addClass('is-active')
          .siblings()
          .removeClass('is-active');
      },
    },
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 40,
      },
    },
  });

  $('.company-slider-nav-item').click(function () {
    const activeIndex = $(this).index();
    swiperCompany.slideTo(activeIndex, 500);
  });
}
